<template>
	<div class="app-main__inner">
		<div class="container-fluid">
			<div class="bg-white h-full sh-pd">
				<div class="row">
					<div class="col-md-12">
						<h2 class="shopify-header">Shopify Guide</h2>
					</div>
					<hr>
					<div class="col-md-12">
						<p class="text-headline">
							1. Gå till Settings > Apps & Sales Channel, därefter klicka Develop Apps och Allow Custom Apps.
						</p>
						<div>
							<img src="../../assets/images/shopify/shopify_guide_1..png" alt="shopify guide"
								 class="shopify-image"/>
						</div>
					</div>
					<div class="col-md-12">
						<p class="text-headline">
							2. Klicka på Skapa app (Create App), döp Appen till “Automatisera Mera" och klicka sedan på Spara.
						</p>
						<p class="text-headline">
							3. Klicka på Konfigurera Admin API behörigheter (Configure Admin API Scopes) och bocka för både läs- och skrivbehörigheter
							för nedan scopes och klicka sedan på Spara:
						</p>
						<div>
							<img src="../../assets/images/shopify/shopify_guide_2.png" alt="shopify guide"
								 class="shopify-image"/>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="list-th">
									<p>-Tilldelade distributionsordrar  (Assigned fulfillment orders)</p>
									<p>-Kunder (Customers)</p>
									<p>-Rabatter (Discounts)</p>
									<p>-Orderutkast (Draft orders)</p>
									<p>-Distributionstjänster (Fulfillment services)</p>
									<p>-Presentkort (Gift cards)</p>
									<p>-Lager (Inventory)</p>
									<p>-Beställningsuppdrag hanterade av försäljaren (Merchant-managed fulfillment orders)</p>
									<p>-Orderredigering (Order editing)</p>
									<p>-Ordrar (Orders)</p>
									<p>-Prisregler (Price rules)</p>
									<p>-Betalningsvillkor (Payment terms)</p>
									<p>-Produktflöden (Product feeds)</p>
									<p>-Frakt (Shipping)</p>
									<p>-Returer (Returns)</p>
									<p>-Produkter (Products)</p>
									<p>-Produktlistningar (Product listings)</p>
									<p>-Butikens platser  (Shop locales)</p>
									<p>-Shopify Payments-konton (Shopify Payments accounts)</p>
									<p>-Bankkonton för Shopify Payments (Shopify Payments bank accounts)</p>
									<p>-Tvister gällande Shopify Payments  (Shopify Payments disputes)</p>
									<p>-Shopify Payments-utbetalningar (Shopify Payments payouts)</p>
									<p>-Distributionsordrar från tredje part  (Third-party fulfillmentorders)</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<p class="text-headline">
							4. Klicka på Installera app (Install App).
						</p>
						<div>
							<img src="../../assets/images/shopify/shopify_guide_3..png" alt="shopify guide"
								 class="shopify-image"/>
						</div>
					</div>
					<div class="col-md-12">
						<p class="text-headline">
							5. Nu får du fram en API nyckel som du kopierar och klistrar in i dina app inställningar i
							Automatisera Mera kundportal.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ShopifyGuide"
}
</script>

<style scoped>
.sh-pd {
	padding: 13px;
	border: 1px solid #ffffff;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.shopify-header {
	font-size: 17px !important;
	font-weight: 800;
	padding-bottom: 20px;
	border-bottom: #dee2e6 solid 1px;
}

.text-headline {
	margin-bottom: 13px;
	padding-top: 13px;
}

.list-th {
	padding-top: 14px;
	padding-bottom: 12px;
	padding-left: 36px;
}

.list-th > p {
	padding-bottom: 4px !important;
}

.shopify-image {
	height: 358px;
	margin: auto;
}

@media only screen and (max-width: 600px) {
	.list-th > p {
		padding-bottom: 0px !important;
	}
}
</style>
